import axios from "axios";

//const back = axios.create({ baseURL: 'http://localhost:3000/api' })
const back = axios.create({ baseURL: 'https://ramseguros.com.co:3030/api' })

back.interceptors.response.use(
  (response) => response,
  (error) => {
    if(!error.response){
      console.error('Error de conexión con el servidor')
    }

    return Promise.reject(error)
  }
)

back.interceptors.request.use((config) => {
  config.headers.token = localStorage.getItem('token')
  return config
}, (error) => {
  return Promise.reject(error)
})

export default back